.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  width: 100%;
  color: #09d3ac;
}

.filter-grid {
  margin-bottom: 4.8rem;
}

.filter-box {
  padding-top: 20px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ebf0f0;
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.no-data {
  padding-top: 2rem;;
  display: flex;
  justify-content: center;
  font-size: calc(5px + 2vmin);
}

.info-label {
  font-size: 1rem;
}

.info {
  color: darkgrey;
}

.status-div {
  background-color: #0749BC;
  border-radius: 50px;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
  margin-top: -10px;
  font-size: 1rem;
}

.evidence-status-div {
  background-color: #2769da;
  border-radius: 50px;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.status-label {
  color: white;
  padding-left: 12px;
  padding-right: 12px;
}


.notes {
  float: 'right';
}

.evidence-card {
  background-color: white;
}

.excel-download-button {
  background-color: #0749BC;
  color: white;
}